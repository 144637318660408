import axios from 'axios';

window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import $ from 'jquery';

window.$ = $;

import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    integrations: [],
});

import 'flowbite';
import 'flowbite-datepicker';

import Datepicker from 'flowbite-datepicker/Datepicker';

window.Datepicker = Datepicker;

import flatpickr from "flatpickr";

window.flatpickr = flatpickr;

import DataTable from 'datatables.net-dt';
import languageIT from 'datatables.net-plugins/i18n/it-IT.mjs';
import 'datatables.net-dt/css/dataTables.dataTables.min.css';

window.DataTable = DataTable;
window.DataTable_languageIT = languageIT;

import * as imageMapResize from 'image-map-resizer/js/imageMapResizer.js';

window.imageMapResize = imageMapResize;

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// import Pusher from 'pusher-js';
// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'mt1',
//     wsHost: import.meta.env.VITE_PUSHER_HOST ? import.meta.env.VITE_PUSHER_HOST : `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
//     wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
//     wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
//     forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
//     enabledTransports: ['ws', 'wss'],
// });
