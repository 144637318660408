import './bootstrap';

function initSearchToggle() {
    $('#searchIcon').click(function (el) {
        if ($("#searchInput").val()) {
            $('#form-search').submit();
        } else {
            $('#searchInput').focus();
            // $("#searchField").toggleClass('hidden');
        }
    });
}

function initFilters() {
    // $('#openFilterModal').modal();
}

function initDelegationSelect() {
    $('.delegationSelect').change(function () {
        const selectedDelegationId = $(this).val();

        const csrfToken = $('meta[name="csrf-token"]').attr('content');

        // Make an Ajax request to update the session
        $.ajax({
            type: 'POST',
            url: config.routes.session_set_delegation,
            data: {
                _token: csrfToken,
                delegation_id: selectedDelegationId
            },
            success: function (response) {
                // Handle the response if needed
                console.log(response);
                location.reload();
            }
        });
    });
}

$(function () {
    initDelegationSelect();
    initSearchToggle();
    initFilters();

    $('.search-btn').click(function () {
        $('#mobile-search').toggle();
    });

    /*const datepickerEl = document.getElementById('datepickerSingle');
    new window.Datepicker(datepickerEl, {
        // options
    });*/
});
